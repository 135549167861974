import axios from 'axios';

export default {
  state: {
    api: {
      components: 'scssml:orders',
    },
    orders: [],
    payments(rootState) {
      return [
        {
          name: rootState.i18n.t('status.payments.cash'),
          id: 1,
        },
        {
          name: rootState.i18n.t('status.payments.card'),
          id: 3,
        },
        {
          name: rootState.i18n.t('status.payments.transfer'),
          id: 4,
        },
        {
          name: rootState.i18n.t('status.payments.deposit'),
          id: 2,
        },
      ];
    },
    paidList(rootState) {
      return [
        {
          id: 3,
          name: rootState.i18n.t('status.paid.no'),
        },
        {
          id: 4,
          name: rootState.i18n.t('status.paid.partly'),
        },
        {
          id: 5,
          name: rootState.i18n.t('status.paid.yes'),
        },
        {
          id: 6,
          name: rootState.i18n.t('status.paid.noPaid'),
        },
      ];
    },
    status(rootState) {
      return [
        {
          name: rootState.i18n.t('status.orders.inWork'),
          color: 'red lighten-4',
          id: 1,
        },
        {
          name: rootState.i18n.t('status.orders.done'),
          color: 'blue lighten-4',
          id: 2,
        },
        {
          name: rootState.i18n.t('status.orders.completed'),
          color: 'yellow lighten-4',
          id: 3,
        },
        {
          name: rootState.i18n.t('status.orders.finished'),
          color: 'green lighten-4',
          id: 4,
        },
        {
          name: rootState.i18n.t('status.orders.contractorsInWorks'),
          color: 'deep-orange accent-1',
          id: 5,
        },
        {
          name: rootState.i18n.t('status.orders.cancelled'),
          color: 'blue-grey lighten-3',
          id: 6,
        },
      ];
    },
    countDoneOrders: 0,
    countWorkOrders: 0,
  },
  getters: {
    getOrdersApiUrl(state, getters, rootState, rootGetters) {
      const url = rootGetters.getApiUrl;
      url.searchParams.set('c', state.api.components);

      return url.toString();
    },
    getOrders(state) {
      return state.orders;
    },
    getPayments(state, getters, rootState) {
      return state.payments(rootState);
    },
    getPaidList(state, getters, rootState) {
      return state.paidList(rootState);
    },
    getStatus(state, getters, rootState) {
      return state.status(rootState);
    },
    getOrder: (state) => (id) => {
      const find = state.orders.find((item) => item.ID === id);
      return find || false;
    },
    getClientOrdersSum: (state) => (client) => {
      const orders = state.orders.filter((item) => item.UF_CLIENT === client);
      return orders.reduce((sum, item) => {
        const sumOrder = item.goods.reduce((sumGoods, good) => {
          const sumGood = Math.round(good.UF_QUANTITY * good.UF_PRICE);

          return sumGoods + sumGood;
        }, 0);

        return sum + sumOrder;
      }, 0);
    },
    getClientOrdersDepositSum: (state) => (client) => {
      const orders = state.orders.filter((item) => item.UF_CLIENT === client
                                                   && item.UF_PAYMENT === 2);
      return orders.reduce((sum, item) => {
        const sumOrder = item.goods.reduce((sumGoods, good) => {
          const sumGood = Math.round(good.UF_QUANTITY * good.UF_PRICE);

          return sumGoods + sumGood;
        }, 0);

        return sum + sumOrder;
      }, 0);
    },
    getCountDoneOrders(state) {
      return state.countDoneOrders;
    },
    getCountWorkOrders(state) {
      return state.countWorkOrders;
    },
  },
  mutations: {
    setCountWorkOrders(state, { total }) {
      state.countWorkOrders = total;
    },
    setCountDoneOrders(state, { total }) {
      state.countDoneOrders = total;
    },
    addOrder(state, { item }) {
      state.orders.push(item);
    },
    setOrders(state, { items }) {
      state.orders = items;
    },
    updateOrder(state, { id, item }) {
      const index = state.orders.findIndex((order) => order.ID === id);

      if (index !== false) {
        const order = item;
        order.ID = id;

        state.orders.splice(index, 1, item);
      }
    },
    setStatusOrder(state, { id, status }) {
      const findItem = state.orders.find((order) => order.ID === id);

      if (findItem) {
        findItem.UF_STATUS = status;
      }
    },
  },
  actions: {
    getOrders({ getters }, { params }) {
      return new Promise((resolve) => {
        const url = new URL(getters.getOrdersApiUrl);
        url.searchParams.set('action', 'getOrders');

        axios
          .get(url.toString(), { params: { params } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getOrder({ getters }, id) {
      return new Promise((resolve) => {
        const url = new URL(getters.getOrdersApiUrl);
        url.searchParams.set('action', 'getOrder');

        axios
          .get(url.toString(), { params: { id } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    addOrder({ getters, dispatch }, params) {
      return new Promise((resolve) => {
        const url = new URL(getters.getOrdersApiUrl);
        url.searchParams.set('action', 'addOrder');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response);

            dispatch('getCountWorkOrders');
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    updateOrder({ getters, dispatch }, params) {
      return new Promise((resolve) => {
        const url = new URL(getters.getOrdersApiUrl);
        url.searchParams.set('action', 'updateOrder');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response);

            dispatch('getCountWorkOrders');
            dispatch('getCountDoneOrders');
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getCountWorkOrders({ dispatch, commit }) {
      const params = {
        filter: {
          UF_STATUS: 1,
        },
        select: ['ID'],
      };

      dispatch('getOrders', { params }).then((result) => {
        const total = result.data.data.count;

        commit('setCountWorkOrders', { total });
      });
    },
    getCountDoneOrders({ dispatch, commit }) {
      const params = {
        filter: {
          UF_STATUS: 3,
        },
        select: ['ID'],
      };

      dispatch('getOrders', { params }).then((result) => {
        const total = result.data.data.count;

        commit('setCountDoneOrders', { total });
      });
    },
    setStatusOrder({ commit, getters }, { id, status }) {
      commit('setStatusOrder', { id, status });

      localStorage.orders = JSON.stringify(getters.getOrders);
    },
    getCsv({ getters }, { params }) {
      return new Promise((resolve) => {
        const url = new URL(getters.getOrdersApiUrl);
        url.searchParams.set('action', 'getCsv');

        axios
          .get(url.toString(), { params: { params } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    setOrders({ commit }, { items }) {
      commit('setOrders', { items });
    },
  },
  modules: {
  },
};
