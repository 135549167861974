<template>
  <v-app>
    <v-app-bar
      app
      dense
      dark
      clipped-left
      clipped-right
      fixed
      color="teal"
      class="no-print"
      v-if="$store.getters.getAuth"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <MenuTop></MenuTop>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      fixed
      color="grey lighten-5"
      class="no-print"
      :right="sidebarRight"
      v-if="$store.getters.getAuth"
    >
      <AccountInfo></AccountInfo>

      <v-divider></v-divider>

      <MenuMain></MenuMain>
    </v-navigation-drawer>

    <v-main>
      <v-container
        fluid
      >
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import MenuTop from '@/components/MenuTop.vue';
import AccountInfo from '@/components/AccountInfo.vue';
import MenuMain from '@/components/MenuMain.vue';

export default {
  name: 'App',
  components: {
    MenuTop,
    AccountInfo,
    MenuMain,
  },
  data: () => ({
    drawer: false,
  }),
  computed: {
    sidebarRight() {
      return this.$vuetify.rtl;
    },
  },
  methods: {
    isAuthorized() {
      this.$store.dispatch('isAuthorized').then((response) => {
        if (response) {
          this.$router.push('/');
        }
      });
    },
    setLang(lang) {
      this.$root.$i18n.locale = lang;
      this.$vuetify.lang.current = lang;
      this.$vuetify.rtl = lang === 'he';
      this.$store.dispatch('setLang', { lang });
    },
  },
  created() {
    this.setLang('he');
    this.isAuthorized();
  },
};
</script>
