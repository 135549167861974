module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "items": {
          "ordersAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить заказ"])},
          "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рассылки"])},
          "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиенты"])},
          "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архив"])}
        },
        "status": {
          "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готово к выдаче"])},
          "inWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В работе"])}
        }
      },
      "he": {
        "items": {
          "ordersAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הזמנה חדשה"])},
          "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["רשימת תפוצה"])},
          "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לקוחות"])},
          "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ארכיון"])}
        },
        "status": {
          "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["דווח ללקוח"])},
          "inWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בעבודה"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"ru":{"items":{"ordersAdd":"Добавить заказ","subscribe":"Рассылки","clients":"Клиенты","archive":"Архив"},"status":{"completed":"Готово к выдаче","inWork":"В работе"}},"he":{"items":{"ordersAdd":"הזמנה חדשה","subscribe":"רשימת תפוצה","clients":"לקוחות","archive":"ארכיון"},"status":{"completed":"דווח ללקוח","inWork":"בעבודה"}}}')
  delete Component.options._Ctor
  
}
