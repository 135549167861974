import axios from 'axios';

export default {
  state: {
    api: {
      components: 'scssml:sectionsGoods',
    },
    groupsGoods: [],
  },
  getters: {
    getGroupsGoodsApiUrl(state, getters, rootState, rootGetters) {
      const url = rootGetters.getApiUrl;
      url.searchParams.set('c', state.api.components);

      return url.toString();
    },
    getGroupsGoods(state) {
      return state.groupsGoods;
    },
  },
  mutations: {
    setGroupsGoods(state, { items }) {
      state.groupsGoods = items;
    },
  },
  actions: {
    getGroupsGoods({ getters }, { params }) {
      return new Promise((resolve) => {
        const url = new URL(getters.getGroupsGoodsApiUrl);
        url.searchParams.set('action', 'getGroupsGoods');

        axios
          .get(url.toString(), { params: { params } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getGroupGoods({ getters }, id) {
      return new Promise((resolve) => {
        const url = new URL(getters.getGroupsGoodsApiUrl);
        url.searchParams.set('action', 'getGroupGoods');

        axios
          .get(url.toString(), { params: { id } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    addGroupGoods({ getters, dispatch }, params) {
      return new Promise((resolve) => {
        const url = new URL(getters.getGroupsGoodsApiUrl);
        url.searchParams.set('action', 'addGroupGoods');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response);

            dispatch('getGroupsGoodsActive');
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    updateGroupGoods({ getters, dispatch }, params) {
      return new Promise((resolve) => {
        const url = new URL(getters.getGroupsGoodsApiUrl);
        url.searchParams.set('action', 'updateGroupGoods');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response);

            dispatch('getGroupsGoodsActive');
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getGroupsGoodsActive({ dispatch, commit }) {
      const params = {
        order: {
          UF_SORT: 'ASC',
        },
        filter: {
          UF_ACTIVE: '1',
        },
        select: ['*'],
      };

      dispatch('getGroupsGoods', { params }).then((result) => {
        const items = result.data.data.result;

        commit('setGroupsGoods', { items });
      });
    },
    setGroupsGoods({ commit }, { items }) {
      commit('setGroupsGoods', { items });
    },
  },
  modules: {
  },
};
