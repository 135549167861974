import axios from 'axios';

export default {
  state: {
    api: {
      components: 'scssml:goods',
    },
    goods: [],
  },
  getters: {
    getGoodsApiUrl(state, getters, rootState, rootGetters) {
      const url = rootGetters.getApiUrl;
      url.searchParams.set('c', state.api.components);

      return url.toString();
    },
  },
  mutations: {
  },
  actions: {
    getGoods({ getters }, { params }) {
      return new Promise((resolve) => {
        const url = new URL(getters.getGoodsApiUrl);
        url.searchParams.set('action', 'getGoods');

        axios
          .get(url.toString(), { params: { params } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getGood({ getters }, id) {
      return new Promise((resolve) => {
        const url = new URL(getters.getGoodsApiUrl);
        url.searchParams.set('action', 'getGood');

        axios
          .get(url.toString(), { params: { id } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    addGood({ getters }, params) {
      return new Promise((resolve) => {
        const url = new URL(getters.getGoodsApiUrl);
        url.searchParams.set('action', 'addGood');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    updateGood({ getters }, params) {
      return new Promise((resolve) => {
        const url = new URL(getters.getGoodsApiUrl);
        url.searchParams.set('action', 'updateGood');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
  },
  modules: {
  },
};
