<i18n>
{
  "ru": {
    "logout": "Выйти"
  },
  "he": {
    "logout": "צא"
  }
}
</i18n>
<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title class="text-h6">
        {{ name }}
      </v-list-item-title>

      <v-list-item-subtitle>
        {{ login }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-icon>
      <v-icon
        :title="$t('logout')"
        @click="logout"
      >mdi-logout</v-icon>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
export default {
  name: 'AccountInfo',
  computed: {
    name() {
      return this.$store.getters.getUserName;
    },
    login() {
      return this.$store.getters.getUserLogin;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
  },
};
</script>
