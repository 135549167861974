import axios from 'axios';

export default {
  state: {
    api: {
      components: 'scssml:historyOrders',
    },
  },
  getters: {
    getHistoryOrdersApiUrl(state, getters, rootState, rootGetters) {
      const url = rootGetters.getApiUrl;
      url.searchParams.set('c', state.api.components);

      return url.toString();
    },
  },
  mutations: {
  },
  actions: {
    getHistoryOrdersList({ getters }, { params }) {
      return new Promise((resolve) => {
        const url = new URL(getters.getHistoryOrdersApiUrl);
        url.searchParams.set('action', 'getHistoryOrdersList');

        axios
          .get(url.toString(), { params: { params } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getHistoryOrders({ getters }, id) {
      return new Promise((resolve) => {
        const url = new URL(getters.getHistoryOrdersApiUrl);
        url.searchParams.set('action', 'getHistoryOrders');

        axios
          .get(url.toString(), { params: { id } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
  },
  modules: {
  },
};
