import Vue from 'vue';
import Vuex from 'vuex';
import i18n from '../plugins/i18n';

import users from './modules/users';
import clients from './modules/clients';
import goods from './modules/goods';
import orders from './modules/orders';
import groupsGoods from './modules/groupsGoods';
import delivery from './modules/delivery';
import receipts from './modules/receipts';
import historyOrders from './modules/historyOrders';
import contractors from './modules/contractors';
import contractorsInWorks from './modules/contractorsInWorks';
import auth from './modules/auth';
import sendMessages from './modules/sendMessages';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    api: {
      path: '/bitrix/services/main/ajax.php',
      mode: 'class',
    },
    i18n,
  },
  getters: {
    getApiUrl(state) {
      const { protocol, hostname, port } = window.location;
      let urlAddress = `${protocol}//${hostname}`;
      if (port.length > 0) {
        urlAddress += `:${port}`;
      }

      const url = new URL(`${urlAddress}${state.api.path}`);
      url.searchParams.set('mode', state.api.mode);

      return url;
    },
    getI18n(state) {
      return state.i18n;
    },
  },
  mutations: {
    setLang(state, { lang }) {
      state.i18n.locale = lang;
    },
  },
  actions: {
    setLang({ commit }, { lang }) {
      commit('setLang', { lang });
    },
  },
  modules: {
    users,
    clients,
    goods,
    orders,
    groupsGoods,
    delivery,
    receipts,
    historyOrders,
    contractors,
    contractorsInWorks,
    auth,
    sendMessages,
  },
});
