import axios from 'axios';

export default {
  state: {
    api: {
      components: 'scssml:contractorsInWorks',
    },
    contractorsInWorksStatus(rootState) {
      return [
        {
          id: 11,
          name: rootState.i18n.t('status.contractorsInWorksStatus.draft'),
        },
        {
          id: 9,
          name: rootState.i18n.t('status.contractorsInWorksStatus.inWork'),
        },
        {
          id: 10,
          name: rootState.i18n.t('status.contractorsInWorksStatus.completed'),
        },
      ];
    },
  },
  getters: {
    getContractorsInWorksApiUrl(state, getters, rootState, rootGetters) {
      const url = rootGetters.getApiUrl;
      url.searchParams.set('c', state.api.components);

      return url.toString();
    },
    getContractorsInWorksStatus(state, getters, rootState) {
      return state.contractorsInWorksStatus(rootState);
    },
  },
  mutations: {
  },
  actions: {
    getOrdersGoodsNoCmplited({ getters }, { params }) {
      return new Promise((resolve) => {
        const url = new URL(getters.getContractorsInWorksApiUrl);
        url.searchParams.set('action', 'getOrdersGoods');

        axios
          .get(url.toString(), { params: { params } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getContractorsInWorksList({ getters }, { params }) {
      return new Promise((resolve) => {
        const url = new URL(getters.getContractorsInWorksApiUrl);
        url.searchParams.set('action', 'getContractorsInWorksList');

        axios
          .get(url.toString(), { params: { params } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getContractorsInWorksItem({ getters }, id) {
      return new Promise((resolve) => {
        const url = new URL(getters.getContractorsInWorksApiUrl);
        url.searchParams.set('action', 'getContractorsInWorksItem');

        axios
          .get(url.toString(), { params: { id } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    addContractorsInWorksItem({ getters }, params) {
      return new Promise((resolve) => {
        const url = new URL(getters.getContractorsInWorksApiUrl);
        url.searchParams.set('action', 'addContractorsInWorksItem');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    updateContractorsInWorksItem({ getters }, params) {
      return new Promise((resolve) => {
        const url = new URL(getters.getContractorsInWorksApiUrl);
        url.searchParams.set('action', 'updateContractorsInWorksItem');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
  },
  modules: {
  },
};
