<i18n>
{
  "ru": {
    "items": {
      "ordersAdd": "Добавить заказ",
      "subscribe": "Рассылки",
      "clients": "Клиенты",
      "archive": "Архив"
    },
    "status": {
      "completed": "Готово к выдаче",
      "inWork": "В работе"
    }
  },
  "he": {
    "items": {
      "ordersAdd": "הזמנה חדשה",
      "subscribe": "רשימת תפוצה",
      "clients": "לקוחות",
      "archive": "ארכיון"
    },
    "status": {
      "completed": "דווח ללקוח",
      "inWork": "בעבודה"
    }
  }
}
</i18n>

<template>
  <div class="d-flex justify-space-between flex-grow-1">
    <template v-for="(item, index) in items">
      <v-chip
        :color="(item.btn) ? 'white' : 'transparent'"
        label
        link
        exact
        :to="item.to"
        class="mx-1"
        :outlined="item.btn"
        :key="`top-menu-item-${index}`"
        v-if="item.show"
      >{{ item.name }}</v-chip>
    </template>

    <v-chip
      color="transparent"
      label
      link
      exact
      to="/orders/status/3/"
      class="mx-1"
    >
      {{ $t('status.completed') }}

      <v-avatar
        right
        class="teal darken-4"
      >{{ countDoneOrders }}</v-avatar>
    </v-chip>

    <v-chip
      color="transparent"
      label
      link
      exact
      to="/orders/status/1/"
      class="mx-1"
    >
      {{ $t('status.inWork') }}

      <v-avatar
        right
        class="teal darken-4"
      >{{ countWorkOrders }}</v-avatar>
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'MenuTop',
  computed: {
    items() {
      return [
        {
          name: this.$t('items.ordersAdd'),
          to: '/orders/add/',
          btn: true,
          show: true,
        },
        {
          name: this.$t('items.subscribe'),
          to: '/send-messages/',
          btn: false,
          show: this.$store.getters.isAdmin,
        },
        {
          name: this.$t('items.clients'),
          to: '/clients/',
          btn: false,
          show: true,
        },
        {
          name: this.$t('items.archive'),
          to: '/orders/status/4/',
          btn: false,
          show: true,
        },
      ];
    },
    countDoneOrders() {
      return this.$store.getters.getCountDoneOrders;
    },
    countWorkOrders() {
      return this.$store.getters.getCountWorkOrders;
    },
  },
  created() {
    this.$store.dispatch('getCountWorkOrders');
    this.$store.dispatch('getCountDoneOrders');
  },
};
</script>
