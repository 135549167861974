module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "items": {
          "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказы"])},
          "contractorsInWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У подрядчиков"])},
          "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиенты"])},
          "goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Услуги"])},
          "groupsGoods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы услуг"])},
          "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рассылки"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менеджеры"])},
          "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Способы доставки"])},
          "receipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Способы приема"])},
          "contractors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подрядчики"])}
        }
      },
      "he": {
        "items": {
          "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הזמנות"])},
          "contractorsInWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["נשלח לספקים"])},
          "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לקוחות"])},
          "goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שרות"])},
          "groupsGoods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קבוצות שירות"])},
          "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["רשימת תפוצה"])},
          "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מנהל"])},
          "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["משלוח"])},
          "receipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קבלה"])},
          "contractors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["קבלן"])}
        }
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"ru":{"items":{"orders":"Заказы","contractorsInWorks":"У подрядчиков","clients":"Клиенты","goods":"Услуги","groupsGoods":"Группы услуг","subscribe":"Рассылки","users":"Менеджеры","delivery":"Способы доставки","receipts":"Способы приема","contractors":"Подрядчики"}},"he":{"items":{"orders":"הזמנות","contractorsInWorks":"נשלח לספקים","clients":"לקוחות","goods":"שרות","groupsGoods":"קבוצות שירות","subscribe":"רשימת תפוצה","users":"מנהל","delivery":"משלוח","receipts":"קבלה","contractors":"קבלן"}}}')
  delete Component.options._Ctor
  
}
