import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Orders',
    component: () => import('../views/Orders/List.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.orders'),
      requiresAuth: true,
    },
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.login'),
      requiresAuth: false,
    },
  },

  {
    path: '/users/',
    name: 'UsersList',
    component: () => import('../views/Users/List.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.users'),
      requiresAuth: true,
    },
  },
  {
    path: '/users/add/',
    name: 'UserAdd',
    component: () => import('../views/Users/Add.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.userAdd'),
      requiresAuth: true,
    },
  },
  {
    path: '/users/:id/',
    name: 'UserEdit',
    component: () => import('../views/Users/Edit.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.userEdit'),
      requiresAuth: true,
    },
  },

  {
    path: '/clients/',
    name: 'ClientsList',
    component: () => import('../views/Clients/List.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.clients'),
      requiresAuth: true,
    },
  },
  {
    path: '/clients/add/',
    name: 'ClientAdd',
    component: () => import('../views/Clients/Add.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.clientAdd'),
      requiresAuth: true,
    },
  },
  {
    path: '/clients/:id/',
    name: 'ClientEdit',
    component: () => import('../views/Clients/Edit.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.clientEdit'),
      requiresAuth: true,
    },
  },

  {
    path: '/goods/',
    name: 'GoodsList',
    component: () => import('../views/Goods/List.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.goods'),
      requiresAuth: true,
    },
  },
  {
    path: '/goods/add/',
    name: 'GoodAdd',
    component: () => import('../views/Goods/Add.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.goodAdd'),
      requiresAuth: true,
    },
  },
  {
    path: '/goods/:id/',
    name: 'GoodEdit',
    component: () => import('../views/Goods/Edit.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.goodEdit'),
      requiresAuth: true,
    },
  },

  {
    path: '/groups-goods/',
    name: 'GroupsGoodsList',
    component: () => import('../views/GroupsGoods/List.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.groupsGoods'),
      requiresAuth: true,
    },
  },
  {
    path: '/groups-goods/add/',
    name: 'GroupGoodsAdd',
    component: () => import('../views/GroupsGoods/Add.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.groupGoodsAdd'),
      requiresAuth: true,
    },
  },
  {
    path: '/groups-goods/:id/',
    name: 'GroupGoodsEdit',
    component: () => import('../views/GroupsGoods/Edit.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.groupGoodsEdit'),
      requiresAuth: true,
    },
  },

  {
    path: '/delivery/',
    name: 'DeliveryList',
    component: () => import('../views/Delivery/List.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.delivery'),
      requiresAuth: true,
    },
  },
  {
    path: '/delivery/add/',
    name: 'DeliveryAdd',
    component: () => import('../views/Delivery/Add.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.deliveryAdd'),
      requiresAuth: true,
    },
  },
  {
    path: '/delivery/:id/',
    name: 'DeliveryEdit',
    component: () => import('../views/Delivery/Edit.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.deliveryEdit'),
      requiresAuth: true,
    },
  },

  {
    path: '/receipts/',
    name: 'ReceiptsList',
    component: () => import('../views/Receipts/List.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.receipts'),
      requiresAuth: true,
    },
  },
  {
    path: '/receipts/add/',
    name: 'ReceiptsAdd',
    component: () => import('../views/Receipts/Add.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.receiptsAdd'),
      requiresAuth: true,
    },
  },
  {
    path: '/receipts/:id/',
    name: 'ReceiptsEdit',
    component: () => import('../views/Receipts/Edit.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.receiptsEdit'),
      requiresAuth: true,
    },
  },

  {
    path: '/orders/',
    name: 'OrdersList',
    component: () => import('../views/Orders/List.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.orders'),
      requiresAuth: true,
    },
  },
  {
    path: '/orders/add/',
    name: 'OrderAdd',
    component: () => import('../views/Orders/Add.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.orderAdd'),
      requiresAuth: true,
    },
  },
  {
    path: '/orders/add/client/:client/',
    name: 'OrderClientAdd',
    component: () => import('../views/Orders/Add.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.orderAdd'),
      requiresAuth: true,
    },
  },
  {
    path: '/orders/:id/',
    name: 'OrderEdit',
    component: () => import('../views/Orders/Edit.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.orderEdit'),
      requiresAuth: true,
    },
  },
  {
    path: '/orders/:id/print/',
    name: 'OrderPrint',
    component: () => import('../views/Orders/Print.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.orderPrint'),
      requiresAuth: true,
    },
  },
  {
    path: '/orders/status/:status/',
    name: 'OrdersWithStatusList',
    component: () => import('../views/Orders/List.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.orders'),
      requiresAuth: true,
    },
  },
  {
    path: '/orders/client/:client/',
    name: 'OrdersClientList',
    component: () => import('../views/Orders/List.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.orders'),
      requiresAuth: true,
    },
  },

  {
    path: '/contractors/',
    name: 'ContractorsList',
    component: () => import('../views/Contractors/List.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.contractors'),
      requiresAuth: true,
    },
  },
  {
    path: '/contractors/add/',
    name: 'ContractorAdd',
    component: () => import('../views/Contractors/Add.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.contractorAdd'),
      requiresAuth: true,
    },
  },
  {
    path: '/contractors/:id/',
    name: 'ContractorEdit',
    component: () => import('../views/Contractors/Edit.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.contractorEdit'),
      requiresAuth: true,
    },
  },

  {
    path: '/contractors-in-works/',
    name: 'ContractorsInWorksList',
    component: () => import('../views/ContractorsInWorks/List.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.contractorsInWorks'),
      requiresAuth: true,
    },
  },
  {
    path: '/contractors-in-works/add/',
    name: 'ContractorsInWorksAdd',
    component: () => import('../views/ContractorsInWorks/Add.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.contractorsInWorksAdd'),
      requiresAuth: true,
    },
  },
  {
    path: '/contractors-in-works/:id/draft/',
    name: 'ContractorsInWorksDraft',
    component: () => import('../views/ContractorsInWorks/Draft.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.contractorsInWorksEdit'),
      requiresAuth: true,
    },
  },
  {
    path: '/contractors-in-works/:id/',
    name: 'ContractorsInWorksEdit',
    component: () => import('../views/ContractorsInWorks/Edit.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.contractorsInWorksEdit'),
      requiresAuth: true,
    },
  },

  {
    path: '/send-messages/',
    name: 'SendMessagesList',
    component: () => import('../views/SendMessages/List.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.SendMessagesList'),
      requiresAuth: true,
    },
  },
  {
    path: '/send-messages/add/',
    name: 'SendMessagesAdd',
    component: () => import('../views/SendMessages/Add.vue'),
    meta: {
      title: () => store.getters.getI18n.t('pageTitle.sendMessagesAdd'),
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.getAuth) {
      next();
      return;
    }
    next('/login');
  } else {
    if (!store.getters.getAuth) {
      next();
      return;
    }

    next('/');
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title();
  });
});

export default router;
