import axios from 'axios';

export default {
  state: {
    api: {
      components: 'scssml:sendMessages',
    },
    sendMessagesTypes(rootState) {
      return [
        {
          id: 12,
          name: rootState.i18n.t('sendMessagesTypes.notification'),
        },
        {
          id: 13,
          name: rootState.i18n.t('sendMessagesTypes.sending'),
        },
      ];
    },
    sendMessagesStatus(rootState) {
      return [
        {
          id: 14,
          name: rootState.i18n.t('sendMessagesStatus.inProgress'),
        },
        {
          id: 15,
          name: rootState.i18n.t('sendMessagesStatus.success'),
        },
        {
          id: 16,
          name: rootState.i18n.t('sendMessagesStatus.error'),
        },
      ];
    },
  },
  getters: {
    getSendMessagesApiUrl(state, getters, rootState, rootGetters) {
      const url = rootGetters.getApiUrl;
      url.searchParams.set('c', state.api.components);

      return url.toString();
    },
    getSendMessagesTypes(state, getters, rootState) {
      return state.sendMessagesTypes(rootState);
    },
    getSendMessagesStatus(state, getters, rootState) {
      return state.sendMessagesStatus(rootState);
    },
  },
  mutations: {
  },
  actions: {
    getSendMessages({ getters }, { params }) {
      return new Promise((resolve) => {
        const url = new URL(getters.getSendMessagesApiUrl);
        url.searchParams.set('action', 'getSendMessages');

        axios
          .get(url.toString(), { params: { params } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    addSendMessages({ getters }, params) {
      return new Promise((resolve) => {
        const url = new URL(getters.getSendMessagesApiUrl);
        url.searchParams.set('action', 'addSendMessages');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
  },
  modules: {
  },
};
