import axios from 'axios';

export default {
  state: {
    api: {
      components: 'scssml:contractors',
    },
    contractors: [],
  },
  getters: {
    getContractorsApiUrl(state, getters, rootState, rootGetters) {
      const url = rootGetters.getApiUrl;
      url.searchParams.set('c', state.api.components);

      return url.toString();
    },
    getContractors(state) {
      return state.contractors;
    },
  },
  mutations: {
    setContractors(state, { items }) {
      state.contractors = items;
    },
  },
  actions: {
    getContractors({ getters }, { params }) {
      return new Promise((resolve) => {
        const url = new URL(getters.getContractorsApiUrl);
        url.searchParams.set('action', 'getContractors');

        axios
          .get(url.toString(), { params: { params } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getContractor({ getters }, id) {
      return new Promise((resolve) => {
        const url = new URL(getters.getContractorsApiUrl);
        url.searchParams.set('action', 'getContractor');

        axios
          .get(url.toString(), { params: { id } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    addContractor({ getters, dispatch }, params) {
      return new Promise((resolve) => {
        const url = new URL(getters.getContractorsApiUrl);
        url.searchParams.set('action', 'addContractor');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response);

            dispatch('getContractorsActive');
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    updateContractor({ getters, dispatch }, params) {
      return new Promise((resolve) => {
        const url = new URL(getters.getContractorsApiUrl);
        url.searchParams.set('action', 'updateContractor');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response);

            dispatch('getContractorsActive');
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getContractorsActive({ dispatch, commit }) {
      const params = {
        order: {
          UF_NAME: 'ASC',
        },
        filter: {
          UF_ACTIVE: '1',
        },
        select: ['*'],
      };

      dispatch('getContractors', { params }).then((result) => {
        const items = result.data.data.result;

        commit('setContractors', { items });
      });
    },
  },
  modules: {
  },
};
