import axios from 'axios';

export default {
  state: {
    api: {
      components: 'scssml:auth',
    },
    auth: false,
    user: null,
  },
  getters: {
    getAuthApiUrl(state, getters, rootState, rootGetters) {
      const url = rootGetters.getApiUrl;
      url.searchParams.set('c', state.api.components);

      return url.toString();
    },
    getUserName(state) {
      return (state.user) ? state.user.name : '';
    },
    getUserGroup(state) {
      return (state.user) ? state.user.group : '';
    },
    getUserLogin(state) {
      return (state.user) ? state.user.login : '';
    },
    getAuth(state) {
      return state.auth;
    },
    isAdmin(state, getters) {
      return getters.getUserGroup === 'admin';
    },
  },
  mutations: {
    setUser(state, { result }) {
      state.user = result;
    },
    setAuth(state, val) {
      state.auth = val;
    },
  },
  actions: {
    isAuthorized({ getters, commit, dispatch }) {
      return new Promise((resolve) => {
        const url = new URL(getters.getAuthApiUrl);
        url.searchParams.set('action', 'isAuthorized');

        axios
          .get(url.toString())
          .then((response) => {
            const { success, result } = response.data.data;

            if (success) {
              commit('setUser', { result });
              commit('setAuth', true);

              dispatch('getGroupsGoodsActive');
              dispatch('getDeliveriesActive');
              dispatch('getReceiptsActive');
              dispatch('getContractorsActive');
            }

            resolve(success);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    login({ getters, commit, dispatch }, params) {
      return new Promise((resolve) => {
        const url = new URL(getters.getAuthApiUrl);
        url.searchParams.set('action', 'login');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            const { success, result } = response.data.data;

            if (success) {
              commit('setUser', { result });
              commit('setAuth', true);

              dispatch('getGroupsGoodsActive');
              dispatch('getDeliveriesActive');
              dispatch('getReceiptsActive');
              dispatch('getContractorsActive');
            }

            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    logout({ getters, commit }) {
      const result = null;

      commit('setUser', { result });
      commit('setAuth', false);

      return new Promise((resolve) => {
        const url = new URL(getters.getAuthApiUrl);
        url.searchParams.set('action', 'logout');

        axios
          .get(url.toString())
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
  },
  modules: {
  },
};
