import axios from 'axios';

export default {
  state: {
    api: {
      components: 'scssml:clients',
    },
    clients: [],
    clientTypes(rootState) {
      return [
        {
          id: 1,
          name: rootState.i18n.t('clientType.entity'),
        },
        {
          id: 2,
          name: rootState.i18n.t('clientType.individual'),
        },
      ];
    },
  },
  getters: {
    getClientsApiUrl(state, getters, rootState, rootGetters) {
      const url = rootGetters.getApiUrl;
      url.searchParams.set('c', state.api.components);

      return url.toString();
    },
    getClients(state) {
      return state.clients;
    },
    getClientTypes(state, getters, rootState) {
      return state.clientTypes(rootState);
    },
    getClient: (state) => (id) => {
      const find = state.clients.find((item) => item.ID === id);
      return find || false;
    },
  },
  mutations: {
    addClient(state, { item }) {
      state.clients.push(item);
    },
    setClients(state, { items }) {
      state.clients = items;
    },
    updateClient(state, { id, item }) {
      const index = state.clients.findIndex((client) => client.ID === id);

      if (index !== false) {
        const client = item;
        client.ID = id;

        state.clients.splice(index, 1, item);
      }
    },
  },
  actions: {
    getClients({ getters }, { params }) {
      return new Promise((resolve) => {
        const url = new URL(getters.getClientsApiUrl);
        url.searchParams.set('action', 'getClients');

        axios
          .get(url.toString(), { params: { params } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getClient({ getters }, id) {
      return new Promise((resolve) => {
        const url = new URL(getters.getClientsApiUrl);
        url.searchParams.set('action', 'getClient');

        axios
          .get(url.toString(), { params: { id } })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    addClient({ getters }, params) {
      return new Promise((resolve) => {
        const url = new URL(getters.getClientsApiUrl);
        url.searchParams.set('action', 'addClient');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    updateClient({ getters }, params) {
      return new Promise((resolve) => {
        const url = new URL(getters.getClientsApiUrl);
        url.searchParams.set('action', 'updateClient');

        axios
          .post(url.toString(), params, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    setClients({ commit }, { items }) {
      commit('setClients', { items });
    },
  },
  modules: {
  },
};
