<i18n>
{
  "ru": {
    "items": {
      "orders": "Заказы",
      "contractorsInWorks": "У подрядчиков",
      "clients": "Клиенты",
      "goods": "Услуги",
      "groupsGoods": "Группы услуг",
      "subscribe": "Рассылки",
      "users": "Менеджеры",
      "delivery": "Способы доставки",
      "receipts": "Способы приема",
      "contractors": "Подрядчики"
    }
  },
  "he": {
    "items": {
      "orders": "הזמנות",
      "contractorsInWorks": "נשלח לספקים",
      "clients": "לקוחות",
      "goods": "שרות",
      "groupsGoods": "קבוצות שירות",
      "subscribe": "רשימת תפוצה",
      "users": "מנהל",
      "delivery": "משלוח",
      "receipts": "קבלה",
      "contractors": "קבלן"
    }
  }
}
</i18n>

<template>
  <v-list
    flat
  >
    <template v-for="(item, index) in items">
      <v-list-item
        :to="item.to"
        :key="`main-menu-item-${index}`"
        v-if="item.show"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
export default {
  name: 'MenuMain',
  computed: {
    items() {
      return [
        {
          name: this.$t('items.orders'),
          to: '/',
          show: true,
        },
        {
          name: this.$t('items.contractorsInWorks'),
          to: '/contractors-in-works/',
          show: true,
        },
        {
          name: this.$t('items.clients'),
          to: '/clients/',
          show: true,
        },
        {
          name: this.$t('items.goods'),
          to: '/goods/',
          show: true,
        },
        {
          name: this.$t('items.groupsGoods'),
          to: '/groups-goods/',
          show: true,
        },
        {
          name: this.$t('items.subscribe'),
          to: '/send-messages/',
          show: this.$store.getters.isAdmin,
        },
        {
          name: this.$t('items.users'),
          to: '/users/',
          show: true,
        },
        {
          name: this.$t('items.delivery'),
          to: '/delivery/',
          show: true,
        },
        {
          name: this.$t('items.receipts'),
          to: '/receipts/',
          show: true,
        },
        {
          name: this.$t('items.contractors'),
          to: '/contractors/',
          show: true,
        },
      ];
    },
  },
};
</script>
